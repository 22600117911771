import React from 'react';

import './Newsletter.css';

const Newsletter = () => (
  <div>
    Newsletter
  </div>
);

export default Newsletter;
