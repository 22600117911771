import React from 'react';

import './FooterOverlay.css';

const FooterOverlay = () => (
  <div>
    FooterOverlay
  </div>
);

export default FooterOverlay;
